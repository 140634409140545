export const products = [
  {
    id: 1,
    salePrice: false,
    price: 880,
    title: "Портал до раю",
    chooseBlocks: false,
    quantityBlock: "38 блоків",
    quantityHero: "1 Герой",
    images: ["/images/портал2_1.jpg", "/images/портал2_21.jpg"]
  },
  {
    id: 2,
    salePrice: false,
    price: 880,
    title: "Портал в нижній світ",
    chooseBlocks: false,
    quantityBlock: "38 блоків",
    quantityHero: "1 Герой",
    images: ["/images/портал1_1.jpg", "/images/портал1_21.jpg"]
  },
  {
    id: 3,
    salePrice: false,
    price: 990,
    title: "Алмазний меч",
    chooseBlocks: false,
    quantityBlock: "42 блоки",
    quantityHero: "1 Герой",
    images: ["/images/Ал.меч_1.jpg", "/images/Ал.меч_2.jpg"]
  },
 /* 
 {
    id: 4,
    salePrice: 999,
    price: 1200,
    title: "Санчата Грінча",
    chooseBlocks: false,
    quantityBlock: "49 блоків",
    quantityHero: "1 Герой (Грінч)",
    images: ["/images/Грінч_1.jpg", "/images/Грінч_2.jpg"]
  },
  */
  {
    id: 5,
    salePrice: 4150,
    price: 4650,
    title: "5 Локацій",
    chooseBlocks: false,
    quantityBlock: "197 блоків",
    quantityHero: "3 Героя",
    images: ["/images/5локацій_1.jpg", "/images/5локацій_2.jpg", "/images/5локацій_3.jpg",]
  },
  {
    id: 6,
    salePrice: false,
    price: 1980,
    title: "Водоспад",
    chooseBlocks: false,
    quantityBlock: "93 блока",
    quantityHero: "2 Героя",
    images: ["/images/водоспад_1.jpg", "/images/водоспад_2.jpg"]
  },
  {
    id: 7,
    salePrice: false,
    price: 3960,
    title: "Кам'яна вежа",
    chooseBlocks: false,
    quantityBlock: "177 блока",
    quantityHero: "3 Героя",
    images: ["/images/вежа_1.jpg", "/images/вежа_2.jpg", "/images/вежа_3.jpg"]
  },
  {
    id: 8,
    salePrice: 25,
    price: 30,
    title: "Блоки",
    chooseBlocks: "Оберіть кількість блоків:",
    quantityBlock: "1 блок на вибір",
    images: ["/images/Блоки_1.jpg", "/images/Блоки_2.jpg"]
  },
  {
    id: 9,
    salePrice: false,
    price: 3080,
    title: "Пастка + Піраміда",
    chooseBlocks: false,
    quantityBlock: "139 блоків",
    quantityHero: "2 Героя",
    images: ["/images/Пастка_1.jpg", "/images/Пастка_2.jpg", "/images/Пастка_3.jpg"]
  },
  {
    id: 10,
    salePrice: 120,
    price: 150,
    title: "Персонажі",
    chooseBlocks: "Оберіть кількість персонажів:",
    quantityBlock: "1 персонаж на вибір",
    images: ["/images/Герої.jpg"]
  }
]
